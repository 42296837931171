import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SettingsService } from 'src/app/main/services/settings.service';
import { UserDetails } from 'src/app/main/types/main.types';
import { MessageService } from 'primeng/api';
import { DOCUMENT } from '@angular/common';

const m: any = {
  mass: {
    IMPERIAL: 'POUND',
    METRIC: 'KILOGRAM',
  },
  length: {
    IMPERIAL: 'MILES',
    METRIC: 'KILOMETERS',
  },
  temperature: {
    IMPERIAL: 'FAHRENHEIT',
    METRIC: 'CELSIUS',
  },
};

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
})
export class GeneralComponent implements OnInit {
  generalFormGroup!: FormGroup;
  modes: any = [];
  mass: any = [];
  length: any = [];
  temperature: any = [];
  time: any = [];
  isLoading = false;
  user: UserDetails;
  disabled = true;
  selectMode: any[] = [];
  isOnLoad = false;
  userDetail$: Observable<UserDetails>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private formBuilder: FormBuilder,
    private settingService: SettingsService,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.generalFormGroup = this.formBuilder.group({
      mass: [''],
      length: [''],
      temperature: [''],
      time: [''],
      mode: [[]],
      connectionApprovalRequired: [''],
    });
    this.modes = [
      { text: 'Beta Tester', value: 'B' },
      { text: 'Dev Tester', value: 'D' },
    ];
    this.mass = [
      { text: 'Pound', value: 'POUND' },
      { text: 'Kilogram', value: 'KILOGRAM' },
    ];
    this.length = [
      { text: 'Miles', value: 'MILES' },
      { text: 'Kilometers', value: 'KILOMETERS' },
    ];
    this.temperature = [
      { text: 'fahrenheit', value: 'FAHRENHEIT' },
      { text: 'Celsius', value: 'CELSIUS' },
    ];
    this.time = [
      { text: 'Speed', value: 'SPEED' },
      { text: 'Pace', value: 'PACE' },
    ];

    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.user = user;
          if (this.user.id) {
            this.getPreferences(this.user.preference.id);
          }
        }),
      );
    this.userDetail$.subscribe();
  }

  onSubmit() {
    this.isLoading = true;
    this.settingService
      .savePreferences(this.user.preference.id, this.getObj())
      .subscribe({
        next: (data: any) => {
          console.error('Request to save preferences succeeded');
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Setting',
            detail: 'Setting Saved Successfully',
          });
          this.disabled = true;
        },
        error: (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Setting',
            detail: err.message,
          });
          console.error(
            'Request to save preferences errored out.' + err.message,
          );
        },
      });
  }

  getPreferences(id: number) {
    this.settingService.getPreferences(id).subscribe({
      next: (data: any) => {
        console.log('Request to get preferences succeeded.');
        const {
          betaUser,
          devUser,
          connectionApprovalRequired,
          lenght,
          mass,
          temperature,
          time,
        } = data;
        this.generalFormGroup.get('mass')?.patchValue(m['mass'][mass]);
        this.generalFormGroup.get('length')?.patchValue(m['length'][lenght]);
        this.generalFormGroup
          .get('temperature')
          ?.patchValue(m['temperature'][temperature]);
        this.generalFormGroup.get('time')?.patchValue(time);
        this.generalFormGroup
          .get('connectionApprovalRequired')
          ?.patchValue(connectionApprovalRequired ? 'YES' : 'NO');
        betaUser && (this.selectMode = this.selectMode.concat(['B']));
        devUser && (this.selectMode = this.selectMode.concat(['D']));
        this.isOnLoad = true;
      },
      error: (err) => {
        console.error('Request to get preferences errored out.' + err.message);
      },
    });
  }

  getObj() {
    const t = this.generalFormGroup;
    const data: any = {};
    console.log(t.get('mode')?.touched);
    if (t.get('mass')?.touched) {
      data.mass = t.get('mass')?.value === 'POUND' ? 'IMPERIAL' : 'METRIC';
      this.disabled = false;
    }

    if (t.get('length')?.touched) {
      data.lenght = t.get('mass')?.value === 'MILES' ? 'IMPERIAL' : 'METRIC';
      this.disabled = false;
    }

    if (t.get('temperature')?.touched) {
      data.temperature =
        t.get('temperature')?.value === 'FAHRENHEIT' ? 'IMPERIAL' : 'METRIC';
      this.disabled = false;
    }

    if (t.get('time')?.touched) {
      data.time = t.get('temperature')?.value;
      this.disabled = false;
    }

    if (t.get('mode')?.touched) {
      data.devUser = this.selectMode.includes('D');
      data.betaUser = this.selectMode.includes('B');
      this.disabled = false;
    }

    if (t.get('connectionApprovalRequired')?.touched) {
      data.connectionApprovalRequired =
        t.get('connectionApprovalRequired')?.value === 'YES';
      this.disabled = false;
    }

    data.id = this.user.preference.id;
    return data;
  }

  onChange(e: any) {
    this.disabled = false;
  }

  scrollToBottom() {
    const disclaimer: any = this.document.querySelector('.disclaimer');
    const matContent = this.document.querySelector('.general');
    console.log(matContent);
    matContent?.scrollTo(0, disclaimer?.offsetTop);
  }
}
