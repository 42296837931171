<div
  class="bg-gray-100 p-[2rem] pt-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300"
>
  <div class="flex flex-col justify-between md:gap-8 lg:flex-row">
    <div class="text-2xl">
      Integration and connected apps
      <p class="mb-4 mt-1 text-sm leading-6 text-gray-600">
        Supercharge your workflow and connect the tool you use every day.
      </p>
    </div>
    <button
      type="button"
      class="mb-4 h-[2.25rem] w-4/6 truncate rounded-md border border-teal-500 bg-white px-5 py-2 text-sm font-semibold text-gray-900 transition-all hover:bg-teal-500 hover:text-white dark:border dark:bg-[#1F242F] dark:text-slate-300 dark:text-slate-500 hover:dark:text-white lg:mb-0 lg:w-auto"
    >
      <fa-icon [icon]="faPlus" size="1x"></fa-icon>
      <span class="ml-2">Request integration</span>
    </button>
  </div>
  <div class="container">
    <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/linear.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Linear</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">
            Streamline software projects, sprints, and bug tracking.
          </p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/github.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Github</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">Link pull requests and automate workflows.</p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/figma.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Figma</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">Embed file previews in projects.</p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/slack.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Slack</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">
            Send notifications to channels and create projects.
          </p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/notion.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Figma</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">Embed file previews in projects.</p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/zappier.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Zappier</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">
            Build custom automations and integrations with apps.
          </p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
      <div class="col-span-6 lg:col-span-3 xl:col-span-2">
        <p-card
          class="integration"
          styleClass="h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-2 flex items-center gap-3">
            <p-image
              src="../../../assets/icons/integration/zendesk.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1 font-semibold">Zendesk</div>
            <p-inputSwitch></p-inputSwitch>
          </div>
          <p class="h-12 text-sm">Link and automate Zendesk tickets.</p>
          <p-divider></p-divider>
          <p-button
            styleClass="text-sm text-teal-500 block"
            class="flex justify-end"
            [text]="true"
          >
            <span class="mr-2">View Integration</span>
          </p-button>
        </p-card>
      </div>
    </div>
  </div>
</div>
