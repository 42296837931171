import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/main/services/settings.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
})
export class PasswordComponent {
  isLoading = false;
  message: string;
  visible: boolean;
  constructor(
    private settingsService: SettingsService,
    private messageService: MessageService,
  ) {}

  sendInstruction() {
    this.isLoading = true;
    this.settingsService.sendPasswordInstructions().subscribe({
      next: (data: any) => {
        this.message = data.message;
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Password',
          detail: data.message,
        });
      },
      error: (err) => {
        this.isLoading = false;
        this.message = err.error.message;
        this.messageService.add({
          severity: 'error',
          summary: 'Password',
          detail: err.error.message,
        });
      },
    });
  }
}
