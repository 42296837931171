import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
})
export class IntegrationComponent {
  faPlus: IconDefinition = faPlus;

  constructor(@Inject(DOCUMENT) private doc: Document) {}
}
