<div class="surface-ground h-full">
  <div class="mx-auto max-w-[720px] p-2">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <div class="container mb-8">
        <h2 class="h-level-2 mb-1 pt-4">Password</h2>
        <p-card class="password mx-auto block md:mx-0 md:my-auto">
          <div class="flex flex-col">
            <h3 class="text-xl">Want to change password?</h3>
            <p>
              Click on the button below to send you the instructions to change
              the password.
            </p>
            <div class="mt-3 flex justify-end">
              <app-button
                [label]="'Send Instruction'"
                (buttonClickEvent)="sendInstruction()"
                [loading]="isLoading"
              ></app-button>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Message"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [breakpoints]="{ '960px': '90vw' }"
>
  <p>{{ message }}</p>
  <ng-template pTemplate="footer">
    <app-button
      class="flex justify-end"
      [label]="'Close'"
      (buttonClickEvent)="visible = false"
    ></app-button>
  </ng-template>
</p-dialog>
