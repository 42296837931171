<button
  pButton
  [type]="type"
  (click)="onButtonClick()"
  [disabled]="disabled"
  [ngClass]="disabled ? '!bg-gray-500 hover:!bg-gray-400' : ''"
  class="mp-button mp-button-filled flex items-center gap-3 rounded-md bg-teal-500 px-5 py-2 leading-6 shadow-sm"
>
  <p-progressSpinner
    [ngClass]="{ hidden: !loading }"
    class="h-[20px] w-[20px]"
    styleClass="w-[20px] h-[20px] transition-all duration-300"
  ></p-progressSpinner>
  <span>{{ label }}</span>
</button>
