import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() label: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Output() buttonClickEvent = new EventEmitter<any>();

  onButtonClick(e?: any) {
    this.buttonClickEvent.emit(e);
  }
}
