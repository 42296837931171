import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Countries, Timezones } from '../../huddle/services/huddle.types';
import data from 'src/configs/countries.json';
import timezones from 'src/configs/timezones.json';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
})
export class BillingComponent {
  countries: Countries[];
  timezone: Timezones[];
  faEnvelope: IconDefinition = faEnvelope;
  faArrowRight: IconDefinition = faArrowRight;

  products: any = [
    {
      id: 1,
      invoiceNumber: 'Invoice #007 – Dec 2022',
      billingDate: 'Dec 1, 2022',
      status: 'Paid',
      amount: 'USD $1.00',
      plan: 'Basic Plan',
    },
    {
      id: 2,
      invoiceNumber: 'Invoice #007 – Dec 2022',
      billingDate: 'Dec 1, 2022',
      status: 'Paid',
      amount: 'USD $1.00',
      plan: 'Basic Plan',
    },
  ];

  constructor(@Inject(DOCUMENT) private doc: Document) {
    this.countries = Array.from(data);
    this.timezone = Array.from(timezones);
  }
}
