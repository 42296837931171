import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { SettingComponent } from './setting.component';
import { GeneralComponent } from './general/general.component';
import { PasswordComponent } from './password/password.component';
import { ButtonComponent } from 'src/app/shared/button/button.component';

import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    SettingComponent,
    GeneralComponent,
    PasswordComponent,
    ButtonComponent,
  ],
  imports: [
    CommonModule,
    SettingRoutingModule,
    CardModule,
    ProgressSpinnerModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    CheckboxModule,
    ToastModule,
    TooltipModule,
  ],
})
export class SettingModule {}
