<div class="surface-ground general h-full overflow-auto">
  <div class="mx-auto max-w-[720px] p-2">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <div class="container mb-8">
        <div class="mb-1 pt-4 text-xl">Preferences</div>
        <p-card
          class="password mx-auto block md:mx-0 md:my-auto"
          styleClass="dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-slate-800"
        >
          <form [formGroup]="generalFormGroup" (ngSubmit)="onSubmit()">
            <div
              class="about testing-mode grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
            >
              <div class="col-span-6 md:col-span-6">
                <label for="mode" class="form-title mb-1 block leading-6">
                  Testing Mode
                  <span
                    class="material-icons !text-sm"
                    [pTooltip]="tooltipContent"
                    tooltipStyleClass="testing-mode"
                    tooltipEvent="focus"
                    tabindex="-1"
                    >info</span
                  >
                </label>
                <div class="flex gap-3">
                  <p-checkbox
                    [(ngModel)]="selectMode"
                    formControlName="mode"
                    value="D"
                    inputId="dev"
                    (onChange)="onChange($event)"
                    class="pointer-events-none"
                  ></p-checkbox>
                  <label class="ml-3" for="dev" class="pointer-events-none"
                    >Dev Tester</label
                  >
                </div>
                <div class="flex gap-3">
                  <p-checkbox
                    [(ngModel)]="selectMode"
                    value="B"
                    formControlName="mode"
                    inputId="beta"
                    (onChange)="onChange($event)"
                    class="pointer-events-none"
                  ></p-checkbox>
                  <label class="ml-3" for="beta" class="pointer-events-none"
                    >Beta Tester</label
                  >
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="mode" class="form-title mb-1 block leading-6"
                  >Connection Approval Required?
                  <span
                    class="material-icons !text-sm"
                    tooltipEvent="focus"
                    tabindex="-1"
                    pTooltip="Do users require your approval to follow you?"
                    >info</span
                  >
                </label>
                <div class="flex flex-row gap-8">
                  <div class="field-checkbox">
                    <p-radioButton
                      inputId="yes"
                      value="YES"
                      styleClass="mp-radiobutton"
                      (onClick)="onChange($event)"
                      formControlName="connectionApprovalRequired"
                    ></p-radioButton>

                    <label for="yes" class="ml-2">Yes</label>
                  </div>
                  <div class="field-checkbox">
                    <p-radioButton
                      inputId="no"
                      value="NO"
                      styleClass="mp-radiobutton"
                      (onClick)="onChange($event)"
                      formControlName="connectionApprovalRequired"
                    ></p-radioButton>
                    <label for="no" class="ml-2">No</label>
                  </div>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="mode" class="form-title mb-1 block leading-6"
                  >Mass / Weight
                  <sup>
                    <a (click)="scrollToBottom()" class="primary cursor-pointer"
                      >1</a
                    >
                  </sup>
                </label>
                <div class="flex flex-row gap-8">
                  <div class="field-checkbox" *ngFor="let _mass of mass">
                    <p-radioButton
                      [inputId]="_mass.value"
                      [value]="_mass.value"
                      styleClass="mp-radiobutton"
                      (onClick)="onChange($event)"
                      formControlName="mass"
                    ></p-radioButton>
                    <label [for]="_mass.value" class="ml-2">{{
                      _mass.text
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="mode" class="form-title mb-1 block leading-6">
                  Length
                  <sup>
                    <a (click)="scrollToBottom()" class="primary cursor-pointer"
                      >1</a
                    >
                  </sup>
                </label>
                <div class="flex flex-row gap-8">
                  <div class="field-checkbox" *ngFor="let _length of length">
                    <p-radioButton
                      [inputId]="_length.value"
                      [value]="_length.value"
                      styleClass="mp-radiobutton"
                      formControlName="length"
                      (onClick)="onChange($event)"
                    ></p-radioButton>
                    <label [for]="_length.value" class="ml-2">{{
                      _length.text
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="mode" class="form-title mb-1 block leading-6"
                  >Temperature
                  <sup>
                    <a (click)="scrollToBottom()" class="primary cursor-pointer"
                      >1</a
                    >
                  </sup>
                </label>
                <div class="flex flex-row gap-8">
                  <div class="field-checkbox" *ngFor="let temp of temperature">
                    <p-radioButton
                      [inputId]="temp.value"
                      [value]="temp.value"
                      styleClass="mp-radiobutton"
                      formControlName="temperature"
                      (onClick)="onChange($event)"
                    ></p-radioButton>
                    <label [for]="temp.value" class="ml-2">{{
                      temp.text
                    }}</label>
                  </div>
                </div>
              </div>

              <div class="col-span-6 md:col-span-6">
                <label for="mode" class="form-title mb-1 block leading-6">
                  Time
                  <sup>
                    <a (click)="scrollToBottom()" class="primary cursor-pointer"
                      >1</a
                    >
                  </sup>
                </label>
                <div class="flex flex-row gap-8">
                  <div class="field-checkbox" *ngFor="let t of time">
                    <p-radioButton
                      [inputId]="t.value"
                      [value]="t.value"
                      styleClass="mp-radiobutton"
                      formControlName="time"
                      (onClick)="onChange($event)"
                    ></p-radioButton>
                    <label [for]="t.value" class="ml-2">{{ t.text }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3 flex justify-end gap-4">
              <app-button
                [label]="'Save'"
                type="submit"
                [disabled]="disabled"
                [loading]="isLoading"
              ></app-button>
            </div>
          </form>
        </p-card>
      </div>
      <section id="disclaimer">
        <div class="disclaimer -mx-2 flex w-full flex-col p-3">
          <h4 class="text-base"><sup>1</sup>Feature Coming Soon</h4>
          <p class="ps-1 pt-1 text-sm">
            This feature is currently in development and will be available in a
            future update.
          </p>
        </div>
      </section>
    </div>
  </div>
</div>
<ng-template #tooltipContent>
  <div class="align-items-center flex">
    If you are interested in becoming a development or beta tester, please reach
    out to support&commat;siddhatek.com.
  </div>
</ng-template>
