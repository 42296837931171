<div
  class="bg-gray-100 p-[2rem] pt-3 dark:border-[#1F242F] dark:bg-[#1F242F] dark:text-slate-300"
>
  <div class="text-2xl">Billing</div>
  <p class="mb-4 mt-1 text-sm leading-6 text-gray-600">
    Manage your billing and payment details.
  </p>
  <div class="container">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="col-span-6 xl:col-span-3">
        <p-card
          styleClass="p-0 billing h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-5 flex justify-between">
            <div class="flex flex-col gap-2">
              <div class="flex gap-5 text-base font-bold">
                <h4 class="inline">Basic</h4>
                <p-chip styleClass="text-sm bg-white border border-[#D0D5DD]"
                  >Monthly</p-chip
                >
              </div>
              <div class="text-xs">Our most popular plan for small teams.</div>
            </div>
            <div class="flex items-end gap-2">
              <h1 class="text-4xl">$10</h1>
              <span class="text-sm">per month</span>
            </div>
          </div>
          <span class="mb-2 block text-sm font-medium">14 of 20 users</span>
          <div class="h-2 w-full rounded-full bg-gray-200 dark:bg-gray-700">
            <div class="h-2 rounded-full bg-blue-600" style="width: 45%"></div>
          </div>
          <p-divider></p-divider>
          <p-button styleClass="text-sm text-teal-500 block" [text]="true">
            <span class="mr-2">Upgrade Plan</span>
            <fa-icon [icon]="faArrowRight" size="1x"></fa-icon>
          </p-button>
        </p-card>
      </div>

      <div class="col-span-6 xl:col-span-3">
        <p-card
          styleClass="p-0 payment h-52 dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
        >
          <div class="mb-5 flex justify-between">
            <div class="flex flex-col gap-2">
              <div class="flex gap-5 text-base font-bold">
                <h4 class="inline">Payment Method</h4>
              </div>
              <div class="text-xs">Change how you pay for your plan.</div>
            </div>
          </div>
          <div class="flex gap-4 rounded-md border p-3.5">
            <p-image
              src="../../../assets/icons/billing/visa.svg"
              alt="Image"
              styleClass="card"
            ></p-image>
            <div class="flex-1">
              <span class="block text-xs">Visa Ending in 1234</span>
              <span class="block text-xs">Expiry 06/2025</span>
              <span class="text-xs">
                <fa-icon [icon]="faEnvelope" size="1x"></fa-icon>
                billing&#64;untitledui.com
              </span>
            </div>
            <button
              type="button"
              class="h-[2.25rem] rounded-md border border-teal-500 px-5 py-2 text-sm font-semibold text-gray-900 transition-all hover:bg-teal-500 hover:text-white dark:text-slate-500 hover:dark:text-white"
            >
              Edit
            </button>
          </div>
        </p-card>
      </div>
    </div>
  </div>
  <div class="mt-5 text-xl">Billing and invoicing</div>
  <p class="mb-4 mt-1 text-sm leading-6 text-gray-600">
    Pick an account plan that fits your workflow.
  </p>
  <div class="container">
    <p-card
      styleClass="dark:text-slate-300 dark:bg-[#0C111D] dark:border dark:border-[#1F242F]"
    >
      <p-table
        [value]="products"
        dataKey="id"
        [tableStyle]="{ 'min-width': '50rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th
              style="width: 4rem"
              class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
            >
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th
              class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
            >
              Invoice
            </th>
            <th
              class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
            >
              Billing Date
            </th>
            <th
              class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
            >
              Status
            </th>
            <th
              class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
            >
              Amount
            </th>
            <th
              class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
            >
              Plan
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr
            class="dark:border dark:border-[#1F242F] dark:bg-[#0C111D] dark:text-slate-300"
          >
            <td>
              <p-tableCheckbox [value]="product"></p-tableCheckbox>
            </td>
            <td>
              <div class="flex items-center gap-4">
                <p-image
                  src="../../../assets/icons/billing/pdf.svg"
                  alt="Image"
                ></p-image>
                <span>{{ product.invoiceNumber }}</span>
              </div>
            </td>
            <td>{{ product.billingDate }}</td>
            <td>{{ product.status }}</td>
            <td>{{ product.amount }}</td>
            <td>{{ product.plan }}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>
</div>
