import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { SettingComponent } from './setting.component';
import { BillingComponent } from './billing/billing.component';
import { IntegrationComponent } from './integration/integration.component';
import { GeneralComponent } from './general/general.component';
import { PasswordComponent } from './password/password.component';
import { authGuard } from '../../utilities';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: SettingComponent,
    children: [
      {
        path: '',
        component: GeneralComponent,
      },
      {
        path: 'billing',
        component: BillingComponent,
      },
      {
        path: 'integration',
        component: IntegrationComponent,
      },
      {
        path: 'password',
        component: PasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingRoutingModule {}
